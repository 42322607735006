import PodcastCard, {
  LISTEN_ON_SPOTIFY,
  WATCH_ON_YOUTUBE,
} from '@/components/shared/Post/Podcast'
import SEO from '@/components/shared/SEO'
import { graphql } from 'gatsby'

function PodcastLink({
  text,
  type,
}: {
  text: string
  type: 'youtube' | 'spotify'
}) {
  return (
    <PodcastCard.PodcastLink
      text={text}
      type={type}
      className="text-white transition duration-300 border-white group hover:border-blue-500"
      textClassName="text-sm tablet:heading-6 desktop:heading-6 group-hover:text-blue-500 transition duration-300"
      arrowClassName="stroke-white group-hover:stroke-blue-500 transition duration-300"
    />
  )
}

const Podcast = (props: any) => {
  const { data } = props
  const podcasts = data.allWpPost.nodes.map((podcast: any) => ({
    ...podcast,
    youtubeLink: podcast.vhl_fields.podcastYoutubeLink,
    spotifyLink: podcast.vhl_fields.podcastSpotifyLink,
    featuredImage:
      podcast.featuredImage.node.localFile.childImageSharp.gatsbyImageData,
  }))
  return (
    <>
      <SEO title="Podcast" />
      {/* <HeroSection podcast={podcasts[0]} /> */}
      <div className="flex flex-col px-4 py-8 desktop:py-[96px] desktop:px-44 space-y-2 bg-gray-900 text-white">
        <PodcastCard {...podcasts[0]} className="flex flex-col">
          <>
            <PodcastCard.YoutubeVideo className="aspect-w-16 aspect-h-9" />
            <div className="flex flex-col justify-between desktop:flex-row">
              <PodcastCard.Title className="py-4 subheading-5 tablet:heading-4 desktop:subheading-5" />
              <div className="flex flex-col py-4 tablet:flex-row tablet:space-x-12 desktop:space-x-4">
                <PodcastLink type="youtube" text={WATCH_ON_YOUTUBE} />
                <PodcastLink type="spotify" text={LISTEN_ON_SPOTIFY} />
              </div>
            </div>
          </>
        </PodcastCard>
      </div>
    </>
  )
}

export const query = graphql`
  {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { parentDatabaseId: { eq: 515 } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        excerpt
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        vhl_fields {
          podcastYoutubeLink
          podcastSpotifyLink
        }
      }
    }
  }
`

export default Podcast
